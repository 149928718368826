import axios from "axios";
import { LoginMailPopUpType } from "../type/type";
import {
  AddLessonTypeApi,
  AddTopicTypeApi,
  BirthCertificateInformationType,
  CreateFullTestType,
  EditTopicTypeApi,
  ParentInformationType,
  ParentPassportInformationType,
  PersonalInformationType,
  overWriteMainInfo,
  uploadDocType,
} from "../type/typeApi";
import { instance } from "./axios";

export const registrationAPI = {
  getCost(classId: number, is_extended: boolean) {
    return instance.get(`get_cost?class_id=${classId}&is_extended=${is_extended}`);
  },
  getSchools() {
    return instance.get(`get_schools`);
  },
  getDisciplines(numberClass: number) {
    return instance.get(`get_class_disciplines?class_id=${numberClass}`);
  },
  registrationOverwrite(data: overWriteMainInfo) {
    return instance.put(`update_user_information`, {
      ...data,
    });
  },
};
export const personalInformationAPI = {
  uploadPersonalInformation({
    first_name,
    second_name,
    gender,
    birth_date,
    insurance_number,
    citizenship,
    patronymic,
    user_id,
  }: PersonalInformationType) {
    return instance.post(
      `upload_personal_information?first_name=${first_name}&second_name=${second_name}&gender=${gender}&birth_date=${birth_date}&insurance_number=${insurance_number}&citizenship=${citizenship}${
        patronymic ? `&patronymic=${patronymic}` : ""
      }${user_id ? `&user_id=${user_id}` : ""}`
    );
  },
  uploadBirthCertificateInformation({
    series,
    number,
    date_of_issue,
    issued_by,
    user_id,
  }: BirthCertificateInformationType) {
    return instance.post(
      `upload_birth_certificate_information?series=${series}&number=${number}&date_of_issue=${date_of_issue}&issued_by=${issued_by}${
        user_id ? `&user_id=${user_id}` : ""
      }`
    );
  },
  uploadParentInformation({
    first_name,
    second_name,
    relation_degree,
    birth_date,
    convenient_messenger,
    living_address,
    patronymic,
    user_id,
  }: ParentInformationType) {
    return instance.post(
      `upload_parent_information?firstname=${first_name}&second_name=${second_name}&relation_degree=${relation_degree}&birth_date=${birth_date}&living_address=${living_address}&convenient_messenger=${convenient_messenger}${
        patronymic ? `&patronymic=${patronymic}` : ""
      }${user_id ? `&user_id=${user_id}` : ""}`
    );
  },
  uploadParentPassportInformation({
    pasport_series,
    pasport_number,
    pasport_date,
    issued_by,
    department_code,
    registration_address,
    passport_type,
    user_id,
  }: ParentPassportInformationType) {
    return instance.post(
      `upload_parent_passport_information?series=${pasport_series}&number=${pasport_number}&date_of_issue=${pasport_date}&issued_by=${issued_by}&department_code=${department_code}&registration_address=${registration_address}&passport_type=${passport_type}${
        user_id ? `&user_id=${user_id}` : ""
      }`
    );
  },
};

export const authAPI = {
  authMe() {
    return instance.get("get_current_user");
  },
  login(data: LoginMailPopUpType) {
    return instance.post("login", {
      username: data.email,
      password: data.password,
    });
  },
  logout() {
    return instance.put("logout");
  },
};

export const profileAPI = {
  getStudents(offset: number, limit: number) {
    return instance.get(`get_users?offset=${offset}&limit=${limit}`);
  },
  getStudent(id: number) {
    return instance.get(`get_user_information?user_id=${id}`);
  },
  getDocuments(id: number) {
    return instance.get(`get_user_documents?user_id=${id}`);
  },
  getSchool() {
    return instance.get(`get_schools`);
  },
};

export const documentAPI = {
  changeStatusDoc({ id, approve_status, text = "" }: { id: number; approve_status: boolean; text?: string }) {
    return instance.put(
      `documents/change_document_status?document_id=${id}&approve_status=${approve_status}&details=${text}`
    );
  },
  uploadDocuments(data: uploadDocType) {
    const form = new FormData();
    form.append("type_of_files", data.type_of_files);
    //@ts-ignore
    form.append("category_id", data.category_id);

    data.files.forEach((item: any) => {
      form.append("files", item);
    });

    return instance.post(`upload_documents`, form);
  },
  getDocument(id: number) {
    return instance.get(`document?document_id=${id}`);
  },
};

export const coursesAPI = {
  getDisciplines(id: number) {
    return instance.get(`get_binded_class_disciplines?class_id=${id}`);
  },
  getAllDisciplines() {
    return instance.get("get_all_disciplines");
  },
  removeDisciplines(id: number) {
    return instance.put(`remove_discipline?discipline_id=${id}`);
  },
  addDisciplines(name: string, icon_url: string) {
    return instance.post(`add_discipline`, {
      name,
      icon_url,
    });
  },
  editDisciplines(id: number, name: string) {
    return instance.post(`update_discipline?discipline_id=${id}`, {
      name,
    });
  },
  bindDisciplines(class_id: number, discipline_id: number) {
    return instance.post(`bind_discipline?class_id=${class_id}&discipline_id=${discipline_id}`);
  },
  unbindDisciplines(class_id: number, discipline_id: number) {
    return instance.post(`unbind_discipline?class_id=${class_id}&discipline_id=${discipline_id}`);
  },
};

export const topicApi = {
  async addTopic({ class_id, discipline_id, name }: AddTopicTypeApi) {
    return instance.post(`category/add_category?name=${name}&class_id=${class_id}&discipline_id=${discipline_id}`);
  },
  async editTopic({ category_id, name }: EditTopicTypeApi) {
    return instance.put(`category/update_category?category_id=${category_id}&name=${name}`);
  },
  async swapTopic(first_category_id: number, second_category_id: number) {
    return instance.put(
      `category/swap_categories?first_category_id=${first_category_id}&second_category_id=${second_category_id}`
    );
  },
  async removeTopic(category_id: number) {
    return instance.post(`category/remove_category?category_id=${category_id}`);
  },
};

export const lessonsAPI = {
  async getLessons(classId: number, disciplineId: number) {
    return instance.get(`lessons/get_lessons?class_id=${classId}&discipline_id=${disciplineId}`);
  },

  async addLesson({ class_id, discipline_id, name, content = "", icon_url = "", category_id }: AddLessonTypeApi) {
    return instance.post(`lessons/add_lesson`, {
      class_id,
      discipline_id,
      name,
      content,
      icon_url,
      category_id,
    });
  },

  async removeLesson(lessonId: number) {
    return instance.put(`lessons/remove_lesson?lesson_id=${lessonId}`);
  },

  async swapLessons(first_lesson_id: number, second_lesson_id: number) {
    return instance.put(`lessons/swap_lessons?first_lesson_id=${first_lesson_id}&second_lesson_id=${second_lesson_id}`);
  },

  async addLessonAfter(first_lesson_id: number, second_lesson_id: number) {
    return instance.put(
      `lessons/add_lesson_after?first_lesson_id=${first_lesson_id}&second_lesson_id=${second_lesson_id}`
    );
  },

  async bindLesson(lesson_id: number, category_id: number) {
    return instance.put(`lessons/bind_lesson?lesson_id=${lesson_id}&category_id=${category_id}`);
  },

  async getLesson(lesson_id: number) {
    return instance.get(`lessons/get_lesson?lesson_id=${lesson_id}`);
  },

  async updateLesson(lesson_id: number, name: string, content: string) {
    return instance.put(`lessons/update_lesson`, {
      lesson_id,
      name,
      content,
    });
  },
};

export const contentsAPI = {
  async uploadContent(fileType: string, file: any) {
    const form = new FormData();
    form.append("files", file);
    return instance.post(`contents/upload_content?file_type=${fileType}`, form);
  },
  async deleteContent(id: number) {
    return instance.post(`contents/rm?content_id=${id}`);
  },
};

export const testsAPI = {
  async getTestAdmin(test_id: number) {
    return instance.get(`tests/get_test_by_admin?test_id=${test_id}`);
  },
  async create(body: CreateFullTestType) {
    return instance.post("tests/create_full_test", body);
  },
};

export const contractsAPI = {
  getPersonalConsent(user_id: number) {
    return instance.get(`contracts/get_personal_consent?user_id=${user_id}`, { responseType: "blob" });
  },
  getEnrollmentpatition(user_id: number) {
    return instance.get(`contracts/get_enrollment_petition?user_id=${user_id}`, { responseType: "blob" });
  },
};
