import {Select} from 'antd';
import {Controller} from 'react-hook-form';

const OptionInput = ({control, data, name} : any) => {

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required: "Поле обязательно для заполнение",
            }}
            render={({field}) => (
                <>
                    <Select  {...field}
                             className={"ant__select"}
                             options={data}
                             listHeight={130}
                    />

                </>
            )}
        />
    );
};

export default OptionInput;