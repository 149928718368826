import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadingStatus } from "./appReducer";
import { profileAPI } from "../api/api";
import { DataType } from "../components/Profile/ProfilePage/ProfileStudents";

export interface MyDocTypeItem {
  id: number;
  type: string;
  url: string;
  approved: boolean;
  details: string;
  file_extension: string;
}
export interface MyDocType {
  "Документы родителя": Array<MyDocTypeItem>;
  "Документы ученика": Array<MyDocTypeItem>;
  "Справки": Array<MyDocTypeItem>;
}

export interface InitialStateType {
  students: Array<DataType> | undefined;
  student: any;
  docs: MyDocType | null;
  countUsers: number | null;
}

const initialState: InitialStateType = {
  students: undefined,
  student: null,
  docs: null,
  countUsers: null,
};

export const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setStudents: (state, action: PayloadAction<Array<any>>) => {
      state.students = action.payload;
    },
    setStudent: (state, action: PayloadAction<any>) => {
      state.student = action.payload;
    },
    setDoc: (state, action: PayloadAction<MyDocType>) => {
      state.docs = action.payload;
    },
    setCountUser: (state, action: PayloadAction<number>) => {
      state.countUsers = action.payload;
    },
  },
});

export const { setStudents, setStudent, setDoc, setCountUser } = profileReducer.actions;

export const getStudents =
  (offset: number = 0, limit: number = 20) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await profileAPI.getStudents(offset, limit);

      let data = response.data.users.map((e: any) => ({
        key: e.id,
        id: e.id,
        username: e.username,
        firstname: e.first_name,
        secondName: e.second_name,
        patronymic: e.patronymic,
        className: e.class.id,
        schoolName: e.school.name,
      }));

      dispatch(setCountUser(response.data.count));
      dispatch(setStudents(data));
    } catch (err: any) {
      if (err.response) {
        console.log(err.response);
      }
      console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const getStudent = (id: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await profileAPI.getStudent(id);

    dispatch(setStudent(response.data));
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
    console.log(err);
  }

  dispatch(loadingStatus(false));
};

export const getDoc = (id: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await profileAPI.getDocuments(id);

    dispatch(setDoc(response.data));
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
    console.log(err);
  }

  dispatch(loadingStatus(false));
};

export default profileReducer.reducer;
