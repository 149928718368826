import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loadingStatus } from './appReducer'
import { coursesAPI, profileAPI } from "../api/api";
import { DataType } from '../components/Profile/ProfilePage/ProfileStudents';


export interface InitialStateType {
    currentClass: null | number
    bindDiscipline: null | Array<any>
    unbindDiscipline: null | Array<any>
    allDiscipline: null | Array<any>
}

const initialState: InitialStateType = {
    currentClass: null,
    bindDiscipline: null,
    unbindDiscipline: null,
    allDiscipline: null
}

export const coursesReducer = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCurrentClass: (state, action: PayloadAction<number>) => {
            state.currentClass = action.payload
        },
        
        setDisciplines: (state, action: PayloadAction<{ bindDiscipline: Array<any>, unbindDiscipline: Array<any> }>) => {
            state.bindDiscipline = action.payload.bindDiscipline
            state.unbindDiscipline = action.payload.unbindDiscipline
        },
        removeDisciplines: (state) => {
            state.bindDiscipline = null
            state.unbindDiscipline = null
            state.currentClass = null
        },
        
        setAllDisciplines: (state, action: PayloadAction<Array<any>>) => {
            state.allDiscipline = action.payload
        },
        
    },
})

export const {setCurrentClass, setDisciplines, removeDisciplines, setAllDisciplines} = coursesReducer.actions

export const getDisciplines = (id: number) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.getDisciplines(id)
        dispatch(setDisciplines({
            bindDiscipline: response.data.binded_disciplines.map((e: any) => ({
                key: e.id,
                id: e.id,
                name: e.name,
                icon_url: e.icon_url
            })),
            unbindDiscipline: response.data.unbinded_disciplines.map((e: any) => ({
                key: e.id,
                id: e.id,
                name: e.name,
                icon_url: e.icon_url
            })),
        }))
        dispatch(setCurrentClass(id))
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const toBindDiscipline = (idClass: number, idDisc: number) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.bindDisciplines(idClass, idDisc)
        dispatch(getDisciplines(idClass))
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const toUnBindDiscipline = (idClass: number, idDisc: number) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.unbindDisciplines(idClass, idDisc)
        dispatch(getDisciplines(idClass))
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const getAllDisciplines = () => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.getAllDisciplines()
        dispatch(setAllDisciplines(response.data.map((e: any) => ({
            key: e.id,
            icon_url: e.icon_url,
            id: e.id,
            name: e.name
        }))))
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const removeDiscipline = (id: number) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.removeDisciplines(id)
        dispatch(getAllDisciplines())
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const addDiscipline = (name: string, icon_url: string) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.addDisciplines(name, icon_url)
        dispatch(getAllDisciplines)
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export const editDiscipline = (id: number, name: string) => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await coursesAPI.editDisciplines(id, name)
        dispatch(getAllDisciplines)
        
    } catch (err: any) {
        if (err.response) {
            console.log(err.response)
        }
        console.log(err)
    }
    
    dispatch(loadingStatus(false))
}

export default coursesReducer.reducer