import {configureStore} from "@reduxjs/toolkit";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import coursesReducer from "./coursesReducer";
import textBooksReducer from "./textBooksReducer";


export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        profile: profileReducer,
        courses: coursesReducer,
        textbooks: textBooksReducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),


    devTools: process.env.NODE_ENV !== 'production',
})


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

//@ts-ignore
window.store = store;