import { Select } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const ClassInput = (control: any) => {
  const data = [
    { value: 1, label: "1 Класс" },
    { value: 2, label: "2 Класс" },
    { value: 3, label: "3 Класс" },
    { value: 4, label: "4 Класс" },
    { value: 5, label: "5 Класс" },
    { value: 6, label: "6 Класс" },
    { value: 7, label: "7 Класс" },
    { value: 8, label: "8 Класс" },
    { value: 9, label: "9 Класс" },
    { value: 10, label: "10 Класс" },
    { value: 11, label: "11 Класс" },
  ];

  return (
    <Controller
      control={control.control}
      name="classNumber"
      rules={{
        required: "Поле обязательно для заполнение",
      }}
      render={({ field }) => (
        <>
          <Select
            {...field}
            className={"ant__select"}
            options={data}
            // defaultValue={1}
            placeholder={"Выберите класс"}
            listHeight={130}
          />
        </>
      )}
    />
  );
};

export default ClassInput;
