import { PlusOutlined } from "@ant-design/icons";
import { GetProp, Upload, UploadFile, UploadProps, Image } from "antd";
import React, { useEffect, useState } from "react";
import { contentsAPI } from "../../api/api";
import { RcFile } from "antd/es/upload";
import { requiredField } from "../../utils/validators/validators";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadImage = ({
  register,
  name,
  setValue,
  reset,
  onSet,
}: {
  name: string;
  setValue: any;
  reset: any;
  register: any;
  onSet?: string | null;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { ref } = register("thumbnail", requiredField);

  const uploadImg = async (file: RcFile, FileList: RcFile[]) => {
    try {
      const { data } = await contentsAPI.uploadContent("Image", file);
      setPreviewImageUrl(data[0].url);
      setValue(name, data[0].id, { shouldValidate: true });
      setFileList([
        {
          uid: "1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_BACK_URL}contents/${data[0].url.slice(1)}`,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  const deleteImg = () => {
    setPreviewImageUrl(null);
    setFileList([]);
    reset(name, { shouldValidate: true });
    return true;
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </button>
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewOpen(true);
  };

  useEffect(() => {
    if (onSet) {
      setPreviewImageUrl(onSet);
      setFileList([
        {
          uid: "1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_BACK_URL}contents${onSet}`,
        },
      ]);
    }
  }, [onSet]);

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={fileList}
        beforeUpload={uploadImg}
        accept={".jpg,.jpeg,.png"}
        maxCount={1}
        onRemove={deleteImg}
        onPreview={handlePreview}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImageUrl?.length && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible,
          }}
          src={`${process.env.REACT_APP_BACK_URL}contents${previewImageUrl}`}
        />
      )}
    </div>
  );
};

export default UploadImage;
