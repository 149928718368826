import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getDisciplines, removeDisciplines, toBindDiscipline, toUnBindDiscipline } from "../../../store/coursesReducer";
import { Link, NavLink, useParams } from "react-router-dom";
import { COURSES_PATH, SUBJECT_PAGE_PATH } from "../../../utils/const";
import {  MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Table, TableProps } from 'antd';

interface DataType {
    id: number;
    name: string;
    icon_url: string;
}

const ProfileCourses = () => {
    
    const currentClass = useAppSelector((state) => state.courses.currentClass)
    const {bindDiscipline, unbindDiscipline} = useAppSelector((state) => state.courses)
    
    const dispatch = useAppDispatch()
    
    const params = useParams();
    let classNumber = Number(params.class);
    
    const onSetClass = (id: number) => {
        dispatch(getDisciplines(id))
    }
    const onUnBindDiscipline = (id:number)=>{
        dispatch(toUnBindDiscipline(classNumber, id))
    }
    const onBindDiscipline = (id:number)=>{
        dispatch(toBindDiscipline(classNumber, id))
    }
    
    useEffect(()=>{
        if (classNumber){
            dispatch(getDisciplines(classNumber))
        }
        return ()=>{
            dispatch(removeDisciplines())
        }
    },[])
    
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Предмет',
            dataIndex: '',
            key: 'name',
            render: (a) => <Link  to={`${SUBJECT_PAGE_PATH}/${classNumber}/${a.id}`}>{a.name}</Link>
        },
        {
            title: 'Отвязать',
            dataIndex: '',
            render: (a) => <button onClick={()=>onUnBindDiscipline(a.id)}
                                 style={{
                                     position: 'relative',
                                     left: '50%',
                                     transform: 'translateX(-50%)',
                                     display: 'inline-flex',
                                     alignItems: 'center'
                                 }}>
                <MinusCircleFilled style={{fontSize: '20px', color: 'red'}}/> </button>
        },
 
    ];
    const columnsTwo: TableProps<DataType>['columns'] = [
        {
            title: 'Предмет',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Привязать',
            dataIndex: '',
            render: (a) => <button onClick={()=>onBindDiscipline(a.id)}
                                 style={{
                                     position: 'relative',
                                     left: '50%',
                                     transform: 'translateX(-50%)',
                                     display: 'inline-flex',
                                     alignItems: 'center'
                                 }}>
                <PlusCircleFilled  style={{fontSize: '20px', color:'green'}}/> </button>
        },
    ];
    
    
    
    return (
        <div className="courses">
            <div className="courses__title _h3">
                Курсы
            </div>
            <div className="courses__subtitle">
                Выберите класс, чтобы редактировать
            </div>
            <div className="courses__wrap">
                <ul className="courses__list">
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/1`} onClick={() => onSetClass(1)}>
                            1 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink  className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/2`} end onClick={() => onSetClass(2)}>
                            2 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/3`} onClick={() => onSetClass(3)}>
                            3 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/4`} onClick={() => onSetClass(4)}>
                            4 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/5`} onClick={() => onSetClass(5)}>
                            5 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/6`} onClick={() => onSetClass(6)}>
                            6 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/7`} onClick={() => onSetClass(7)}>
                            7 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/8`} onClick={() => onSetClass(8)}>
                            8 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/9`} onClick={() => onSetClass(9)}>
                            9 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/10`} onClick={() => onSetClass(10)}>
                            10 класс
                        </NavLink>
                    </li>
                    <li className="courses__item">
                        <NavLink className={({isActive}) => `courses__link  btn ${isActive ? '_active' : ''}`}
                                 to={`${COURSES_PATH}/11`} onClick={() => onSetClass(11)}>
                            11 класс
                        </NavLink>
                    </li>
                </ul>
            </div>
            {
                currentClass && bindDiscipline && unbindDiscipline? (
                    <>
                        <div className="courses__section">
                            <div className="courses__section-title _h4">
                                Привязанные предметы
                            </div>
                            <Table columns={columns} dataSource={bindDiscipline}  pagination={false}/>
                        </div>
                        <div className="courses__section">
                            <div className="courses__section-title _h4">
                                Непривязанные предметы
                            </div>
                            <Table columns={columnsTwo} dataSource={unbindDiscipline}  pagination={false}/>
                        </div>
                    </>
                ) : ''
            }
        
        </div>
    );
};

export default ProfileCourses;