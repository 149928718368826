import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadingStatus } from "./appReducer";
import { profileAPI } from "../api/api";
import { TextBookService } from "../services/TextBookService";
import { openSuccessNotification } from "../utils/Notification";

export interface InitialStateType {
  books: Array<any> | undefined;
  countBooks: number | null;
}

const initialState: InitialStateType = {
  books: undefined,
  countBooks: null,
};

export const textBooksReducer = createSlice({
  name: "textbooks",
  initialState,
  reducers: {
    setTextBooks: (state, action: PayloadAction<Array<any>>) => {
      state.books = action.payload;
    },

    setCountTextBooks: (state, action: PayloadAction<number>) => {
      state.countBooks = action.payload;
    },
  },
});

export const { setTextBooks, setCountTextBooks } = textBooksReducer.actions;

export const getAllBooks =
  (offset: number = 0, limit: number = 20) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await TextBookService.getAllBook({offset, limit});

      let data = response.data.books.map((e: any) => ({
        key: e.id,
        id: e.id,
        name: e.name,
        author: e.author,
        class_id: e.class.id,
        discipline_id: e.discipline.name,
      }));

      dispatch(setCountTextBooks(response.data.amount_of_books));
      dispatch(setTextBooks(data));
    } catch (err: any) {
      if (err.response) {
        console.log(err.response);
      }
      console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const removeBook = (id: number, currentPage: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));
  try {
    const { data } = await TextBookService.removeBook(id);
    dispatch(getAllBooks(currentPage - 1));
    console.log(data);
    openSuccessNotification("Успешное удаление");
  } catch (e) {
    console.log(e);
  }
  dispatch(loadingStatus(false));
};

export default textBooksReducer.reducer;
