import { coursesAPI } from "../api/api";
import { instance } from "../api/axios";
import { UploadBook } from "../type/typeApi";
import { openErrorNotification, openSuccessNotification } from "../utils/Notification";

export const TextBookService = {
  async upload({ name, author, class_id, discipline_id, thumbnail, file }: UploadBook) {
    try {
      const form = new FormData();
      form.append("file", file);

      await instance
        .post(
          `books/upload_book?name=${name}&author=${author}&class_id=${class_id}&discipline_id=${discipline_id}&thumbnail_content_id=${thumbnail}`,
          form
        )
        .catch((e) => {
          console.error(e);
          openErrorNotification(e?.message, "Ошибка при добавление учебника");
        });
      openSuccessNotification("Успешно", "Учебник добавлен");
    } catch (e) {
      console.log(1);
    }
  },

  async edit({ name, author, class_id, discipline_id, thumbnail, file, book_id }: UploadBook) {
    try {
      const form = new FormData();
      form.append("file", file);

      await instance
        .put(
          `books/update_book?book_id=${book_id}&name=${name}&author=${author}&class_id=${class_id}&discipline_id=${discipline_id}&thumbnail_content_id=${thumbnail}`,
          form
        )
        .catch((e) => {
          console.error(e);
          openErrorNotification(e?.message, "Ошибка при добавление учебника");
        });

      if (file) {
        await instance.post(`books/upload_new_book_file?book_id=${book_id}`, form).catch((e) => {
          console.error(e);
          openErrorNotification(e?.message, "Ошибка при добавление учебника");
        });
      }

      openSuccessNotification("Успешно", "Учебник добавлен");
    } catch (e) {
      console.log(1);
    }
  },

  async getDisciplines(id: number) {
    try {
      const { data } = await coursesAPI.getDisciplines(id);
      return data.binded_disciplines;
    } catch (e: any) {
      return e;
    }
  },

  async getAllBook({ offset=0, limit=20 }: { offset: number; limit: number }) {
    const response: any = await instance.get(`books/get_all_books?offset=${offset}&limit=${limit}`).catch((e) => {
      console.error(e);
      openErrorNotification(e?.message, "Ошибка при получения списка учебников");
      return;
    });
    return response;
  },

  async getBookInfo(id: number) {
    const response: any = await instance.get(`books/get_book_info?book_id=${id}`).catch((e) => {
      console.error(e);
      openErrorNotification(e?.message, "Ошибка при получения информации");
      return;
    });
    return response;
  },

  async removeBook(id: number) {
    const response: any = await instance.post(`books/remove_book?book_id=${id}`).catch((e) => {
      console.error(e);
      openErrorNotification(e?.message, "Ошибка при удаление учебника");
      return;
    });
    return response;
  },
};
