import axios from "axios";

export const instance = axios.create({
  headers: {
    accept: "application/json",
  },
  withCredentials: true,

  //baseURL: "https://super.sdavaykin.ru/"
  baseURL: process.env.REACT_APP_BACK_URL,
});
