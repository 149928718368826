import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Loading from "../Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { initializeApp, showMobMenu } from "../../store/appReducer";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";



const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();


  const loading = useAppSelector((state) => state.app.loading);
  const isInit = useAppSelector((state) => state.app.init);

  useEffect(() => {
    dispatch(showMobMenu(false));
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(initializeApp());
  }, []);

  if (!isInit) {
    return <Loading />;
  }

  return (
    <>
      {loading ? <Loading /> : ""}

      <Header />

      <Breadcrumbs />

      <main className="main">
        <Outlet />
      </main>
    </>
  );
};

export default App;
