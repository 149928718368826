import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ADMIN, ENTRY_PATH } from "../../utils/const";
import IconStore from "../../media/iconStore";
import ProfileMenu from "./ProfileMenu";

const Profile: React.FC = () => {
  let location = useLocation();

  const [isShowMenu, setShowMenu] = useState<boolean>(false);

  const { isAuth, role } = useAppSelector((state) => state.auth);

  const onSetShowMenu = () => {
    setShowMenu(!isShowMenu);
  };


  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  if (!isAuth || role !== ADMIN) {
    return <Navigate to={ENTRY_PATH} />;
  }

  return (
    <section className="cabinet ">
      {location.pathname.indexOf("students") !== -1 ? (
        <img className="cabinet__decor _1" src={IconStore.DecorReg4} alt="reg1.svg" width="464" height="650" />
      ) : (
        <img className="cabinet__decor _1" src={IconStore.DecorReg1} alt="reg1.svg" width="464" height="650" />
      )}

      <div className="container">
        <div className="cabinet__grid">
          <div className="cabinet__aside">
            <div className="cabinet__aside-top">
              <div className="cabinet__aside-top-left">
                <div className="cabinet__aside-top-name _h4">Администратор</div>
              </div>
              <button className="cabinet__aside-top-btn btn btn_2" type="button" onClick={onSetShowMenu}>
                Открыть меню
              </button>
            </div>

            <ProfileMenu isShowMenu={isShowMenu} setShowMenu={onSetShowMenu} />
          </div>

          <div className="cabinet__content">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
