import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAllDisciplines, removeDiscipline } from "../../../store/coursesReducer";
import { Table, TableProps } from "antd";
import { Link } from "react-router-dom";
import { EditFilled, MinusCircleFilled } from "@ant-design/icons";
import { SUBJECTS_CREATE_PATH, SUBJECTS_EDIT_PATH } from "../../../utils/const";

interface DataType {
    id: number;
    name: string;
    icon_url: string;
}

const ProfileSubjects = () => {
    
    const allDiscipline = useAppSelector((state) => state.courses.allDiscipline)
    
    const dispatch = useAppDispatch()
    
    const onRemoveDiscipline = (id:number)=>{
        dispatch(removeDiscipline(id))
    }
    
    useEffect(()=>{
        dispatch(getAllDisciplines())
    },[dispatch])
    
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Предмет',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Редактировать',
            dataIndex: '',
            width: '10%',
            render: (a) => <Link to={`${SUBJECTS_EDIT_PATH}/${a.id}`}
                style={{
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'inline-flex',
                    alignItems: 'center'
                }}>
                <EditFilled  style={{fontSize: '20px', color: '#32b931'}}/> </Link>
        },
        {
            title: 'Удалить',
            dataIndex: '',
            render: (a) => <button onClick={()=>onRemoveDiscipline(a.id)}
                                   style={{
                                       position: 'relative',
                                       left: '50%',
                                       transform: 'translateX(-50%)',
                                       display: 'inline-flex',
                                       alignItems: 'center'
                                   }}>
                <MinusCircleFilled style={{fontSize: '20px', color: 'red'}}/> </button>
        },
    
    ]
    
    return (
        <div className="subjects">
            <div className="subjects__title _h3">
                Предметы
            </div>
            <Link className="subjects__btn btn" to={SUBJECTS_CREATE_PATH}>
                Добавить
            </Link>
            {
                allDiscipline? (
                    <Table columns={columns} dataSource={allDiscipline}  pagination={false}/>
                ) : 'Нет данных'
            }
        </div>
    );
};

export default ProfileSubjects;